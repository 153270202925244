import React from 'react'
import { node } from 'prop-types'

import GlobalStyle from '@/style-guide/GlobalStyle'

const Layout = ({ children }) => (
  <>
    <GlobalStyle />
    {children}
  </>
)

Layout.propTypes = {
  children: node.isRequired
}

export { default as TopBar } from './TopBar'
export { default as Footer } from './Footer'
export { default as Featured } from './Featured'

export default Layout
