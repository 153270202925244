import React from 'react'
import styled, { css } from 'styled-components'
import { bool } from 'prop-types'
import media from 'styled-media-query'

import CategoriesMenu from '@/components/CategoriesMenu'

import { SECTIONS } from '@/helpers/mocks/home'

import { Icon } from '@/style-guide'
import { black, white } from '@/style-guide/colors'

const Container = styled.div`
  justify-content: space-between;
  align-items: center;

  height: 73px;
  margin-top: 110px;
  padding: 0px 100px;
  background: #f6f6f6;
`

const Ghost = styled.div`
  height: 183px;
  background: ${white};
`

const ContainerDesktop = styled(Container)`
  display: none;
  ${media.greaterThan('medium')`
    display: flex;

    ${props =>
      props.stick &&
      css`
        position: fixed;
        top: 0px;
        width: 100%;
        margin-top: 0px;
        z-index: 99;
      `};
  `}
`

const ContainerMobile = styled(Container)`
  display: flex;
  padding: 0px 30px;

  ${media.greaterThan('medium')`
    display: none;
  `}
`

const Option = styled.a`
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  line-height: 26px;
  letter-spacing: 3px;
  padding: 0px 5px;
  color: #c4c4c4;
  cursor: pointer;
  transition: 0.5s;

  &:hover {
    color: #4472de;
  }
`

const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const NavScroll = ({ stick }) => (
  <>
    {stick && <Ghost />}
    <ContainerDesktop stick={stick}>
      {SECTIONS.map((item, index) => (
        <Option key={index} href={`#${item.idSection}`}>
          {item.title.toUpperCase()}
        </Option>
      ))}
    </ContainerDesktop>
    <ContainerMobile>
      <CategoriesMenu
        action={
          <Action>
            <span>Select Calculator Category</span>
            <Icon color={black} name="arrow-down" />
          </Action>
        }
        footer={false}
        style={{ width: '100%' }}
        type="href"
      />
    </ContainerMobile>
  </>
)

NavScroll.propTypes = {
  stick: bool
}

export default NavScroll
