/* eslint-disable react/jsx-curly-newline */
import React, { useState, useEffect } from 'react'
import { Link as LinkComponent } from 'gatsby'
import styled from 'styled-components'
import media from 'styled-media-query'

import { black } from '@/style-guide/colors'
import { Button } from '@/style-guide'

import ArticleContext from '@/context/ArticleContext'

import Article from './Article'

const Container = styled.div`
  padding: 20px 25px;
  margin-top: 80px;

  ${media.greaterThan('medium')`
  	padding: 20px 153px 20px 123px;
		margin-top: 140px;
  `}
`

const Articles = styled.div`
  display: flex;
  flex-direction: column;

  ${media.greaterThan('medium')`
		margin-top: 30px;
		justify-content: center;
		flex-direction: row;
  `}
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${media.greaterThan('medium')`
		padding-left: 30px;
		flex-direction: row;
		align-items: center;
  	justify-content: space-between;
  `}
`

const Title = styled.span`
  color: ${black};
  font-weight: bold;
  font-size: 26.3969px;
  line-height: 46px;

  ${media.greaterThan('medium')`
		font-size: 42px;
		line-height: 50px;
  `};
`

const StyledButton = styled(Button)`
  width: 122px;
  height: 38px;
  letter-spacing: 0.2em;
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  margin-top: 10px;

  ${media.greaterThan('medium')`
		margin-top: 0px;
  `}
`

const Link = styled(LinkComponent)`
  text-decoration: none;
  cursor: pointer;
`

const RecentArticles = () => {
  const [articles, setArticles] = useState([])

  useEffect(() => {
    fetch(`https://cms-calculatex.herokuapp.com/articles?_limit=3&_sort=createdAt:DESC`)
      .then(response => response.json())
      .then(resultArticles => {
        setArticles(resultArticles)
      })
  }, [])

  return (
    <ArticleContext.Consumer>
      {value =>
        !!articles.length && (
          <Container>
            <TitleContainer>
              <Title>Our Latest Article</Title>
              <Link to="/blog">
                <StyledButton label="READ ALL" />
              </Link>
            </TitleContainer>
            <Articles>
              {articles.map((article, index) => (
                <Link key={index} to="/article" onClick={() => value.handleArticleId(article._id)}>
                  <Article article={article} />
                </Link>
              ))}
            </Articles>
          </Container>
        )
      }
    </ArticleContext.Consumer>
  )
}

export default RecentArticles
