import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'

import { black, grey } from '@/style-guide/colors'

import SearchWidget from '@/components/SearchWidget'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  z-index: 1;
  position: relative;

  ${media.greaterThan('medium')`
    padding: 0;
  `}
`

const Content = styled.div`
  width: 100%;
  position: relative;

  ${media.greaterThan('medium')`
    width: 879px;
  `}
`

const Description = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;

  ${media.greaterThan('medium')`
    margin-top: 20px;
    flex-direction: row;
  `}
`

const CalculatorCount = styled.div`
  display: flex;
  align-items: center;
`

const DescriptionText = styled.div`
  width: 100%;
  font-size: 11px;
  line-height: 20px;
  color: ${grey};
  margin-top: 10px;
  padding: 0 10px;

  ${media.greaterThan('medium')`
    margin-top: 0px;
    padding: 0;
    width: 543px;
    margin-left: 120px;
    font-size: 14px;
    line-height: 24px;
  `}
`

const Count = styled.h1`
  font-size: 81.5949px;
  line-height: 88px;
  background: -webkit-linear-gradient(#4f83e0, #83d0ee);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;

  ${media.greaterThan('medium')`
    font-size: 136.489px;
    line-height: 148px;
  `}
`

const CountText = styled.span`
  font-weight: bold;
  font-size: 21.7586px;
  line-height: 88px;
  margin-left: 15px;
  color: ${black};

  ${media.greaterThan('medium')`
    font-size: 36.3969px;
    line-height: 148px;
  `}
`

const SubHeader = () => (
  <Container>
    <Content>
      <SearchWidget />
    </Content>
    <Description>
      <CalculatorCount>
        <Count>80+</Count>
        <CountText>Calculators</CountText>
      </CalculatorCount>
      <DescriptionText>
        The &quot;Calculatex&quot; project is a group of high functioning online calculators which
        have been carefully programmed to work on computers, smart phones and tablets. This project
        is ongoing and is meant for there to be a calculator for any of our communities needs,
        whether they be personal or business.
      </DescriptionText>
    </Description>
  </Container>
)

export default SubHeader
