import React from 'react'

export const CALCULATORS = {
  grade: {
    'final-grade': {
      title: 'Final Grade Calculator',
      description: (
        <>
          <p>
            We present to you the final grade calculator: a simple-to-use tool that will allow you
            to effortlessly calculate your final exam grade so you can focus on studying. In this
            calculator, we will answer the question &quot;what grade do I need on my final?&quot;
            and explain why it matters. On top of all of that, this final exam grade calculator will
            teach you how to calculate your final exam grade by hand or by using our convenient
            average calculator.
          </p>
        </>
      )
    },
    'gpa-to-letter': {
      title: 'GPA to Letter Calculator',
      description: (
        <>
          <p>
            We present to you the final grade calculator: a simple-to-use tool that will allow you
            to effortlessly calculate your final exam grade so you can focus on studying. In this
            calculator, we will answer the question &quot;what grade do I need on my final?&quot;
            and explain why it matters. On top of all of that, this final exam grade calculator will
            teach you how to calculate your final exam grade by hand or by using our convenient
            average calculator.
          </p>
        </>
      )
    },
    gpa: {
      title: 'Gpa Calculator',
      description: (
        <>
          <p>
            We present to you the final grade calculator: a simple-to-use tool that will allow you
            to effortlessly calculate your final exam grade so you can focus on studying. In this
            calculator, we will answer the question &quot;what grade do I need on my final?&quot;
            and explain why it matters. On top of all of that, this final exam grade calculator will
            teach you how to calculate your final exam grade by hand or by using our convenient
            average calculator.
          </p>
        </>
      )
    },
    grade: {
      title: 'Grade Calculator',
      description: (
        <>
          <p>
            We present to you the final grade calculator: a simple-to-use tool that will allow you
            to effortlessly calculate your final exam grade so you can focus on studying. In this
            calculator, we will answer the question &quot;what grade do I need on my final?&quot;
            and explain why it matters. On top of all of that, this final exam grade calculator will
            teach you how to calculate your final exam grade by hand or by using our convenient
            average calculator.
          </p>
        </>
      )
    },
    'high-school-gpa': {
      title: 'High School GPA Calculator',
      description: (
        <>
          <p>
            We present to you the final grade calculator: a simple-to-use tool that will allow you
            to effortlessly calculate your final exam grade so you can focus on studying. In this
            calculator, we will answer the question &quot;what grade do I need on my final?&quot;
            and explain why it matters. On top of all of that, this final exam grade calculator will
            teach you how to calculate your final exam grade by hand or by using our convenient
            average calculator.
          </p>
        </>
      )
    },
    'letter-to-grade': {
      title: 'Letter to Grade Calculator',
      description: (
        <>
          <p>
            We present to you the final grade calculator: a simple-to-use tool that will allow you
            to effortlessly calculate your final exam grade so you can focus on studying. In this
            calculator, we will answer the question &quot;what grade do I need on my final?&quot;
            and explain why it matters. On top of all of that, this final exam grade calculator will
            teach you how to calculate your final exam grade by hand or by using our convenient
            average calculator.
          </p>
        </>
      )
    },
    test: {
      title: 'Test Calculator',
      description: (
        <>
          <p>
            We present to you the final grade calculator: a simple-to-use tool that will allow you
            to effortlessly calculate your final exam grade so you can focus on studying. In this
            calculator, we will answer the question &quot;what grade do I need on my final?&quot;
            and explain why it matters. On top of all of that, this final exam grade calculator will
            teach you how to calculate your final exam grade by hand or by using our convenient
            average calculator.
          </p>
        </>
      )
    }
  },
  'finance-calculators': {
    paypal: {
      title: 'PayPal Calculator',
      description: (
        <>
          <p>
            We present to you the final grade calculator: a simple-to-use tool that will allow you
            to effortlessly calculate your final exam grade so you can focus on studying. In this
            calculator, we will answer the question &quot;what grade do I need on my final?&quot;
            and explain why it matters. On top of all of that, this final exam grade calculator will
            teach you how to calculate your final exam grade by hand or by using our convenient
            average calculator.
          </p>
        </>
      )
    },
    ebay: {
      title: 'Ebay Calculator',
      description: (
        <>
          <p>
            Square footage calculator is an easy tool that enables you to calculate an area in
            square feet as well as perform conversions, such as finding out how many square feet are
            in an acre. In this article, we are going to explain how to calculate square footage and
            understand the square footage formula.
          </p>
          <p>
            Moreover, we will instruct you on how to find the square footage from the area given in
            some other units; that includes converting sq into sq ft (square inches to square feet),
            sq m to sq ft (square meters to square feet) and acres to sq ft (acres to square feet).
            In fact, we&apos;re feeling so generous we will even talk about how to measure the
            square footage of a house.
          </p>
        </>
      )
    },
    stripe: {
      title: 'Stripe Calculator',
      description: (
        <>
          <p>
            Square footage calculator is an easy tool that enables you to calculate an area in
            square feet as well as perform conversions, such as finding out how many square feet are
            in an acre. In this article, we are going to explain how to calculate square footage and
            understand the square footage formula.
          </p>
          <p>
            Moreover, we will instruct you on how to find the square footage from the area given in
            some other units; that includes converting sq into sq ft (square inches to square feet),
            sq m to sq ft (square meters to square feet) and acres to sq ft (acres to square feet).
            In fact, we&apos;re feeling so generous we will even talk about how to measure the
            square footage of a house.
          </p>
        </>
      )
    }
  },
  math: {
    derivative: {
      title: 'Derivative Calculator',
      description: (
        <>
          <p>
            We present to you the final grade calculator: a simple-to-use tool that will allow you
            to effortlessly calculate your final exam grade so you can focus on studying. In this
            calculator, we will answer the question &quot;what grade do I need on my final?&quot;
            and explain why it matters. On top of all of that, this final exam grade calculator will
            teach you how to calculate your final exam grade by hand or by using our convenient
            average calculator.
          </p>
        </>
      )
    },
    integral: {
      title: 'Integral Calculator',
      description: (
        <>
          <p>
            Square footage calculator is an easy tool that enables you to calculate an area in
            square feet as well as perform conversions, such as finding out how many square feet are
            in an acre. In this article, we are going to explain how to calculate square footage and
            understand the square footage formula.
          </p>
          <p>
            Moreover, we will instruct you on how to find the square footage from the area given in
            some other units; that includes converting sq into sq ft (square inches to square feet),
            sq m to sq ft (square meters to square feet) and acres to sq ft (acres to square feet).
            In fact, we&apos;re feeling so generous we will even talk about how to measure the
            square footage of a house.
          </p>
        </>
      )
    }
  }
}

export const CALCULATORS_LIST = [
  {
    title: 'Final Grade Calculator',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc porta mollis nibh utdapibus...',
    category: 'grade',
    link: 'calculators/grade/final-grade'
  },
  {
    title: 'GPA to Letter Calculator',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc porta mollis nibh utdapibus...',
    category: 'grade',
    link: 'calculators/grade/gpa-to-letter'
  },
  {
    title: 'Gpa Calculator',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc porta mollis nibh utdapibus...',
    category: 'grade',
    link: 'calculators/grade/gpa'
  },
  {
    title: 'Grade Calculator',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc porta mollis nibh utdapibus...',
    category: 'grade',
    link: 'calculators/grade/grade'
  },
  {
    title: 'High School GPA Calculator',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc porta mollis nibh utdapibus...',
    category: 'grade',
    link: 'calculators/grade/high-school-gpa'
  },
  {
    title: 'Letter to Grade Calculator',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc porta mollis nibh utdapibus...',
    category: 'grade',
    link: 'calculators/grade/letter-to-grade'
  },
  {
    title: 'Test Calculator',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc porta mollis nibh utdapibus...',
    category: 'grade',
    link: 'calculators/grade/test'
  },
  {
    title: 'PayPal Calculator',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc porta mollis nibh utdapibus...',
    category: 'finance',
    link: 'calculators/finance-calculators/paypal'
  },
  {
    title: 'Ebay Calculator',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc porta mollis nibh utdapibus...',
    category: 'finance',
    link: 'calculators/finance-calculators/ebay'
  },
  {
    title: 'Stripe Calculator',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc porta mollis nibh utdapibus...',
    category: 'finance',
    link: 'calculators/finance-calculators/stripe'
  },
  {
    title: 'Derivative Calculator',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc porta mollis nibh utdapibus...',
    category: 'math',
    link: 'calculators/math/derivative'
  },
  {
    title: 'Integral Calculator',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc porta mollis nibh utdapibus...',
    category: 'math',
    link: 'calculators/math/integral'
  }
]
