import React from 'react'
import { Link as LinkComponent } from 'gatsby'
import { string, bool } from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'

import { black } from '@/style-guide/colors'

const Link = styled(LinkComponent)`
  text-decoration: none;
  color: ${black};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 40px;
  cursor: pointer;

  ${({ simple }) =>
    !simple &&
    media.greaterThan('medium')`
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  `}

  &:hover {
    opacity: 0.6;
  }
`

const Information = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.span`
  font-size: 18px;
  line-height: 29px;
  text-transform: capitalize;
`

const Description = styled.span`
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 5px;

  ${({ simple }) =>
    !simple &&
    media.greaterThan('medium')`
    margin-bottom: 0px;
  `}

  color: #7f92a3;
`

const Category = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 26px;

  letter-spacing: 0.2em;
  text-transform: uppercase;

  border-radius: 4px;
  color: #8091a1;

  background: #f6f6f6;

  padding: 4px 15px;
`

const SearchedRow = ({ title, description, category, simple, link }) => (
  <Link to={link}>
    <Content simple={simple}>
      <Information>
        <Title>{title}</Title>
        <Description simple={simple}>{description}</Description>
      </Information>
      <Category>{category}</Category>
    </Content>
  </Link>
)

SearchedRow.propTypes = {
  title: string,
  description: string,
  category: string,
  simple: bool,
  link: string
}

export default SearchedRow
