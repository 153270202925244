import React from 'react'
import { shape, string, object } from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'

import { white, orange } from '@/style-guide/colors'

const Container = styled.div`
  background: ${white};
  box-shadow: 12px 15px 25px rgba(31, 45, 91, 0.1);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 558px;
  margin-top: 30px;

  ${media.greaterThan('medium')`
    width: 355px;
    margin-top: 0px;
    margin-left: 30px;
  `}
`

const Header = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: ${({ image }) => `url(${image})`};
  width: 100%;
  height: 197px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`

const Body = styled.div`
  padding: 25px 30px;
`

const Title = styled.div`
  color: #000000;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  text-transform: capitalize;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const Text = styled.div`
  font-size: 14px;
  line-height: 26px;
  opacity: 0.5;
  margin-top: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #7f92a3;
`

const Footer = styled.div`
  padding: 0 0 25px 30px;
`

const Link = styled.span`
  color: ${orange};
  text-transform: capitalize;
  font-weight: bold;
  font-size: 16px;
  line-height: 34px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`

const Article = ({ article = {} }) => (
  <Container>
    <Header image={article.image?.url} />
    <Content>
      <Body>
        <Title>{article.title}</Title>
        <Text>{article.text}</Text>
      </Body>
      <Footer>
        <Link>Read More</Link>
      </Footer>
    </Content>
  </Container>
)

Article.propTypes = {
  article: shape({
    title: string,
    image: object,
    text: string
  })
}

export default Article
