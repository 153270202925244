import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'

import footerWaves from '@/assets/images/footer-waves.svg'

import { Icon } from '@/style-guide'

const Container = styled.div`
  background-image: url(${footerWaves});
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 20px;
  height: 160px;
  margin-top: 30px;

  ${media.greaterThan('medium')`
    padding-bottom: 15px;
    height: 238px;
  `}
`

const CopyRight = styled.div`
  display: flex;
  > span {
    color: #f6f6f6;
    font-size: 14px;
    line-height: 14px;

    :not(:first-child) {
      margin-left: 5px;
    }
  }
`

const EndBar = () => (
  <Container>
    <CopyRight>
      <span>Made with by</span>
      <Icon name="heart" color="#FF2D2D" style={{ marginLeft: 5 }} />
      <span>Calculatex. All rights reserved.</span>
    </CopyRight>
  </Container>
)

export default EndBar
