import React, { useState } from 'react'
import styled from 'styled-components'

import { white, black } from '@/style-guide/colors'

import spain from '@/assets/icons/spain.svg'
import usa from '@/assets/icons/usa.svg'

const Container = styled.div`
  position: relative;
`

const Content = styled.div`
  display: ${props => (props.visible ? 'flex' : 'none')};
  width: 140px;
  position: absolute;
  flex-direction: column;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  top: 45px;
  z-index: 5;
  background: ${white};
  box-sizing: border-box;
  box-shadow: 12px 15px 25px rgba(31, 45, 91, 0.1);
`

const Text = styled.span`
  font-size: 14px;
  line-height: 14px;
  margin-left: 50px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`

const Mask = styled.div`
  display: ${props => (props.visible ? 'flex' : 'none')};
  background: rgba(0, 0, 0, 0);
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  cursor: pointer;
`

const Locale = styled.span`
  font-size: 14.5201px;
  font-weight: bold;
  line-height: 18px;
  color: ${black};
`

const Img = styled.img`
  margin-right: 10px;
`

const Locales = () => {
  const [visible, setVisible] = useState('')

  const handleToggleVisible = () => {
    setVisible(!visible)
  }

  return (
    <Container>
      <Text onClick={handleToggleVisible}>English</Text>

      <Content visible={visible}>
        <Row>
          <Img src={usa} alt="USA" />
          <Locale>ENGLISH</Locale>
        </Row>
        <Row>
          <Img src={spain} alt="Spain" />
          <Locale>SPANISH</Locale>
        </Row>
      </Content>
      <Mask onClick={handleToggleVisible} visible={visible} />
    </Container>
  )
}

export default Locales
