/* eslint-disable react/jsx-curly-newline */
import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'

import { black } from '@/style-guide/colors'

import { FEATUREDS } from '@/helpers/mocks/home'

const Container = styled.div`
  width: 100%;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${media.greaterThan('medium')`
		margin-top: 140px;
  `}
`

const Title = styled.span`
  font-size: 26.3969px;
  line-height: 46px;
  font-weight: bold;
  color: ${black};

  ${media.greaterThan('medium')`
    font-size: 36.3969px;
  `}
`

const Featureds = styled.div`
  width: 100%;
  margin-top: 30px;
  padding: 0 25px;
  display: grid;
  grid-template-columns: 160px 160px;
  grid-template-rows: 35px 35px 35px;
  grid-row-gap: 30px;
  grid-column-gap: 20px;
  justify-content: space-between;
  justify-items: center;
  align-items: center;

  ${media.greaterThan('medium')`
    display: flex;
    padding: 0 153px;
  `}
`
const Image = styled.div`
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: ${({ image }) => `url(${image})`};
  width: 96.12px;
  height: 31.95px;

  ${media.greaterThan('medium')`
    width: 147px;
    height: 49px;
  `}
`

const Featured = () => (
  <Container>
    <Title>Featured In</Title>
    <Featureds>
      {FEATUREDS.map(({ image }, index) => (
        <Image key={index} image={image} />
      ))}
    </Featureds>
  </Container>
)

export default Featured
