import React from 'react'
import styled from 'styled-components'

import { white } from '@/style-guide/colors'
import { Icon, Button } from '@/style-guide'

const StyledButton = styled(Button)`
  display: block;
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  color: ${white};
  cursor: pointer;
  padding: 15px;
  border-radius: 50%;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
`

const ButtonUpScroll = () => {
  const handleTopScroll = () => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  return (
    <StyledButton
      onClick={handleTopScroll}
      icon={<Icon name="geometry-2d" size={15} color={white} />}
    />
  )
}

export default ButtonUpScroll
