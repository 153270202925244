import React, { useState, useEffect } from 'react'
import { bool, object, string, func, number } from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'
import Fuse from 'fuse.js'

import { SearchBar, Icon } from '@/style-guide'

import { white } from '@/style-guide/colors'

import { CALCULATORS_LIST } from '@/helpers/mocks/calculators'

import SearchedRow from './SearchedRow'

const StyledSearchBar = styled(SearchBar)`
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  border-bottom-right-radius: ${({ searched, simple }) => (!simple || searched ? '0px' : '6px')};
  border-bottom-left-radius: ${({ searched, simple }) => (!simple || searched ? '0px' : '6px')};
  border-bottom: none;
  padding: 8px 8px 8px 20px;
  z-index: 3;

  position: relative;

  box-shadow: 12px 15px 25px rgba(31, 45, 91, 0.1);
`

const TotalCalculatorBadge = styled.div`
  background-color: #4472de;
  border-radius: 6px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  height: 34.98px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.greaterThan('medium')`
    height: 55px;
  `}

  > span {
    font-size: 10.8111px;
    line-height: 15px;
    color: #dfe9ff;

    ${media.greaterThan('medium')`
      font-size: 17px;
      line-height: 23px;
    `}

    > strong:first-child {
      color: #7be7ff;
    }

    > strong:nth-child(2) {
      color: ${white};
    }
  }
`

const SearchedList = styled.div`
  background-color: ${white};
  border-radius: 6px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  padding-top: 30px;
  padding-bottom: 20px;
  z-index: 2;
  box-shadow: 12px 15px 25px rgba(31, 45, 91, 0.1);

  position: absolute;
  width: 100%;

  top: 71px;

  > a:first-child {
    margin-top: 30px;
  }

  > a:last-child {
    margin-bottom: 20px;
  }

  ${media.greaterThan('medium')`
    top: unset;
  `}

  border: 1px solid #d6dbf0;
`

const Empty = styled.span`
  font-size: 18px;
  line-height: 29px;
  margin-left: 40px;

  color: #7f92a3;
`

const Mask = styled.div`
  display: flex;
  background: rgba(0, 0, 0, 0);
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
`

const SearchWidget = ({
  styleButton,
  iconButton,
  simple,
  className,
  handleToggleVisible,
  count = 33333,
  year = 2020
}) => {
  const [searched, setSearched] = useState(false)
  const [listSearched, setList] = useState([])
  const [fuse, setFuse] = useState(() => {})

  useEffect(() => {
    const options = {
      isCaseSensitive: false,
      findAllMatches: false,
      includeMatches: false,
      includeScore: false,
      useExtendedSearch: false,
      minMatchCharLength: 3,
      shouldSort: true,
      threshold: 0.2,
      location: 0,
      distance: 100,
      keys: ['title', 'category']
    }

    setFuse(new Fuse(CALCULATORS_LIST, options))
  }, [])

  const handleSearch = value => {
    setSearched(true)
    setList(fuse.search(value))
  }

  return (
    <>
      <StyledSearchBar
        onSearch={value => {
          handleSearch(value)
        }}
        onChange={value => {
          handleSearch(value)
        }}
        className={className}
        styleButton={styleButton}
        iconButton={iconButton}
        searched={searched}
        simple={simple}
        icon={!simple && <Icon name="search" color="#092C4C" size={20} />}
      />
      {searched && (
        <>
          <SearchedList onClick={handleToggleVisible}>
            {listSearched.length ? (
              listSearched.map(({ item: { title, description, category, link } }, index) => (
                <SearchedRow
                  key={index}
                  title={title}
                  description={description}
                  category={category}
                  simple={simple}
                  link={link}
                />
              ))
            ) : (
              <Empty>Sorry, we didn&apos;t find anything...</Empty>
            )}
          </SearchedList>
          <Mask
            onClick={() => {
              setList([])
              setSearched(false)
              if (handleToggleVisible) {
                handleToggleVisible()
              }
            }}
          />
        </>
      )}
      {!simple && (
        <TotalCalculatorBadge>
          <span>
            We&apos;ve already did <strong>{count}</strong> calculates from <strong>{year}</strong>.
          </span>
        </TotalCalculatorBadge>
      )}
    </>
  )
}

SearchWidget.propTypes = {
  styleButton: object,
  iconButton: object,
  year: number,
  count: number,
  simple: bool,
  className: string,
  handleToggleVisible: func
}

export default SearchWidget
