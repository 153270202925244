import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'

import bgImageLarger from '@/assets/images/bg_header-larger.svg'
import bgImageSmall from '@/assets/images/bg_header-small.svg'

import { white } from '@/style-guide/colors'
import { Badge as BadgeComponent } from '@/style-guide'

import { LottieImage } from '@/components'

const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;
  padding-top: 65px;
`

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-image: url(${bgImageSmall});
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 369px;
  padding: 40px 32px;

  ${media.greaterThan('medium')`
    background-image: url(${bgImageLarger});
    height: 780px;
    padding-top: 165px;
    padding-left: 153px;
    padding-right: 153px;
  `}
`

const Badge = styled(BadgeComponent)`
  ${media.greaterThan('medium')`
    width: 263px;
  `}
`

const Title = styled.span`
  color: ${white};
  font-weight: bold;
  width: 100%;
  margin-top: 10px;
  font-size: 27px;
  line-height: 37px;

  ${media.greaterThan('medium')`
    font-size: 42px;
    line-height: 50px;
    max-width: 543px;
    margin-top: 20px;
  `}
`

const Description = styled.span`
  font-size: 11px;
  line-height: 20px;
  color: #d1e0fe;
  max-width: 302px;
  margin-top: 10px;

  ${media.greaterThan('medium')`
    max-width: 546px;
    font-size: 16px;
    line-height: 26px;
    margin-top: 15px;
  `}
`

const Logo = styled(LottieImage)`
  height: 240px;
  position: relative;
  top: -120px;

  ${media.greaterThan('medium')`
    height: 733px;
    width: 920px;
    margin-top: 0px;
    position: absolute;
    right: 153px;
    top: 165px;
  `}
`

const Header = () => (
  <Container>
    <Content>
      <Badge badge="New" content="Introducing CalculateX" />
      <Title>If You Can&apos;t Measure It, You Can&apos;t Manage It.</Title>
      <Description>
        Calculatex is the best tool to get results you&apos;re looking for without worrying about
        advanced math. Choose the calculator you want and you&apos;re good to go. As simple as that.
      </Description>
    </Content>
    <Logo source={require('@/assets/lottie-images/notebook.json')} />
  </Container>
)

export default Header
