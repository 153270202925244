import React, { useState } from 'react'
import styled from 'styled-components'

import { orange } from '@/style-guide/colors'

import CardCoffee from '../CardCoffee'

const Container = styled.div`
  position: relative;
`

const Content = styled.div`
  display: ${props => (props.visible ? 'flex' : 'none')};
  width: 360px;
  position: absolute;
  left: 50px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  top: 45px;
  z-index: 5;
  background: radial-gradient(50% 50% at 50% 50%, rgb(68, 114, 222) 0%, rgb(43, 89, 197) 100%);
  box-sizing: border-box;
  box-shadow: 12px 15px 25px rgba(31, 45, 91, 0.1);
`

const Text = styled.span`
  font-size: 14px;
  line-height: 14px;
  margin-left: 50px;
  cursor: pointer;
  font-weight: bold;
  color: ${orange};

  :hover {
    opacity: 0.8;
  }
`

const Mask = styled.div`
  display: ${props => (props.visible ? 'flex' : 'none')};
  background: rgba(0, 0, 0, 0);
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const DonateMenu = () => {
  const [visible, setVisible] = useState('')

  const handleToggleVisible = () => {
    setVisible(!visible)
  }

  return (
    <Container>
      <Text onClick={handleToggleVisible}>Buy Us Coffe</Text>

      <Content visible={visible}>
        <CardCoffee donateVisible />
      </Content>
      <Mask onClick={handleToggleVisible} visible={visible} />
    </Container>
  )
}

export default DonateMenu
