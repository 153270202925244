import React from 'react'
import { Link as LinkComponent } from 'gatsby'
import styled from 'styled-components'
import media from 'styled-media-query'

import topLogo from '@/assets/icons/top-logo.svg'
import calculator from '@/assets/icons/calculator.svg'

import { Icon } from '@/style-guide'
import { black, white } from '@/style-guide/colors'

import CategoriesMenu from '../CategoriesMenu'
import Socials from '../Socials'
import LocalesMenu from './LocalesMenu'
import DonateMenu from './DonateMenu'
import SearchMenu from './SearchMenu'

const Container = styled.div`
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  z-index: 2;
  padding: 0 30px;
  background-color: ${white};

  ${media.greaterThan('medium')`
    padding: 0 153px;
  `}
`

const LogoDesktop = styled.div`
  display: none;

  ${media.greaterThan('medium')`
    display: unset;
  `}
`

const Img = styled.img`
  height: 16px;

  ${media.greaterThan('medium')`
    height: 23px;
      margin-right: 50px;
  `}
`

const LogoMobile = styled.div`
  display: unset;

  ${media.greaterThan('medium')`
    display: none;
  `}
`

const Link = styled(LinkComponent)`
  text-decoration: none;
`

const Action = styled.div`
  display: flex;
  align-items: center;

  *:not(:last-child) {
    margin-right: 5px;
  }
`

const Span = styled.span`
  font-size: 14px;
  line-height: 46px;
  color: ${black};
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`

const Aside = styled.div`
  display: none;
  align-items: center;
  justify-content: space-between;

  ${media.greaterThan('medium')`
    display: flex;
  `}
`
const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${media.greaterThan('medium')`
    width: fit-content;
  `}
`

const Text = styled.span`
  font-size: 14px;
  line-height: 14px;
  margin-left: 50px;
  cursor: pointer;
  color: ${black};

  :hover {
    opacity: 0.8;
  }
`

const StyledSocials = styled(Socials)`
  justify-content: space-around;
  min-width: 170px;
  display: flex;
  margin-left: 50px;
`

const TopBar = () => (
  <Container>
    <Content>
      <LogoDesktop>
        <Link to="/">
          <Img src={topLogo} alt="Calculatex" />
        </Link>
      </LogoDesktop>
      <LogoMobile>
        <SearchMenu action={<Img src={topLogo} alt="Calculatex" />} />
      </LogoMobile>
      <CategoriesMenu
        action={
          <Action>
            <img src={calculator} alt="Calculator" />
            <Span>Calculators</Span>
            <Icon color={black} name="arrow-down" />
          </Action>
        }
        footer
      />
    </Content>
    <Aside>
      <Link to="/about">
        <Text>About us</Text>
      </Link>
      <DonateMenu />
      <StyledSocials />
      <LocalesMenu />
    </Aside>
  </Container>
)

export default TopBar
