import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }

  body {
    height: 100%;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  ul,
  li {
    list-style: none;
  }
`

export default GlobalStyle
