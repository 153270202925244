import React from 'react'
import styled, { css } from 'styled-components'
import { Link as LinkComponent } from 'gatsby'
import { string, bool, func, oneOfType, object } from 'prop-types'

import { darkBlue, orange } from '@/style-guide/colors'
import { Icon } from '@/style-guide'

import SubCategoryContext from '@/context/SubCategoryContext'

const Link = styled(LinkComponent)`
  text-decoration: none;
`

const Item = styled.div`
  cursor: pointer;
  height: 47px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  margin-top: 5px;
  color: #0050aa;

  :hover {
    box-shadow: 4px 10px 12px rgba(0, 0, 0, 0.05);

    > span {
      color: ${orange};
    }

    > div svg path {
      fill: ${orange};
    }
  }

  ${({ active, selected }) =>
    active &&
    !selected &&
    css`
      > span {
        color: ${orange};
      }

      > div svg path {
        fill: ${orange};
      }
    `}

  ${({ selected }) =>
    selected &&
    css`
      height: 16px;

      &:hover {
        box-shadow: unset !important;

        > span {
          color: ${darkBlue};
        }

        > div svg path {
          fill: ${darkBlue};
        }
      }
    `};

  > div {
    width: 40px;
    display: flex;
    justify-content: center;
  }

  > span {
    font-size: 16px;
    line-height: 29px;
    margin-left: 5px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: #c4c4c4;
      cursor: auto;

      :hover {
        box-shadow: unset;

        > span {
          color: #c7c7c7;
        }

        > div svg path {
          fill: #c7c7c7;
        }
      }
    `}
`

const SubCategoryCard = ({
  icon,
  label,
  id,
  category,
  selected = false,
  handleClick,
  link = false,
  disabled = false
}) => (
  <SubCategoryContext.Consumer>
    {value => {
      const content = (
        <Item
          selected={selected}
          active={value.subCategory === id && !link}
          disabled={disabled}
          onClick={() => {
            if (!disabled) {
              if (handleClick) {
                handleClick()
              }

              value.handleSub(id)
            }
          }}
        >
          {icon && (
            <div>
              <Icon name={icon} color={disabled ? '#c7c7c7' : darkBlue} size={18} />
            </div>
          )}
          <span>{label}</span>
        </Item>
      )

      if (link && !disabled) {
        return (
          <Link
            to={`calculator-categories/${category}`}
            onClick={() => !disabled && value.handleSub(id)}
          >
            {content}
          </Link>
        )
      }

      return content
    }}
  </SubCategoryContext.Consumer>
)

SubCategoryCard.propTypes = {
  icon: string,
  id: string,
  category: string,
  label: oneOfType([string, object]),
  selected: bool,
  link: bool,
  disabled: bool,
  handleClick: func
}

export default SubCategoryCard
