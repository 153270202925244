import React, { useState } from 'react'
import { node } from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'

import { white } from '@/style-guide/colors'

import SearchWidget from '@/components/SearchWidget'

const Container = styled.div`
  ${media.greaterThan('medium')`
    position: relative;
  `}
`

const Dropdown = styled.div`
  width: 100%;
  cursor: pointer;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  top: 75px;
  position: absolute;
  z-index: 5;
  background: ${white};
  width: 90%;
  right: 5%;
  box-sizing: border-box;
  box-shadow: 12px 15px 25px rgba(31, 45, 91, 0.1);
  border-radius: 6px;
`

const Mask = styled.div`
  display: flex;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const SearchMenu = ({ action }) => {
  const [visible, setVisible] = useState(false)

  const handleToggleVisible = () => {
    setVisible(!visible)
  }

  return (
    <Container>
      <Dropdown onClick={handleToggleVisible}>{action}</Dropdown>
      {visible && (
        <>
          <Content>
            <SearchWidget handleToggleVisible={handleToggleVisible} />
          </Content>
          <Mask onClick={handleToggleVisible} />
        </>
      )}
    </Container>
  )
}

SearchMenu.propTypes = {
  action: node
}

export default SearchMenu
