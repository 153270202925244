/* eslint-disable import/no-dynamic-require */
import React, { useEffect, useRef } from 'react'
import { string, object } from 'prop-types'
import lottie from 'lottie-web'

const LottieImage = ({ source, className }) => {
  const animeRef = useRef(null)

  useEffect(() => {
    lottie.loadAnimation({
      container: animeRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: source
    })
  }, [source])

  return <div ref={animeRef} className={className} />
}

LottieImage.propTypes = {
  source: object.isRequired,
  className: string
}

export default LottieImage
