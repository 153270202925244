/* eslint-disable react/jsx-curly-newline */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'

import { white } from '@/style-guide/colors'

import { SECTIONS } from '@/helpers/mocks/home'

import Layout, { TopBar, Featured, Footer } from '@/components/Layout'

import Header from './Header'
import SubHeader from './SubHeader'
import NavScroll from './NavScroll'
import ButtonUpScroll from './ButtonUpScroll'
import Section from './Section'
import RecentArticles from './RecentArticles'

const Container = styled.div`
  background-color: ${white};
  height: 100vh;
  width: 100%;
`

const Content = styled.div`
  padding-bottom: 40px;
  margin-top: -160px;

  ${media.greaterThan('medium')`
    margin-top: 120px;
  `}
`

const Home = () => {
  const [stick, setStick] = useState(false)

  const scrollFunction = () => {
    setStick(document.body.scrollTop > 1394 || document.documentElement.scrollTop > 1394)
  }

  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.onscroll = () => scrollFunction()
    }
  }, [])

  return (
    <Layout>
      <Container>
        <TopBar />
        <Header />
        <Content>
          <SubHeader />
          <NavScroll stick={stick} />
          {SECTIONS.map(({ title, description, image, inverted, items, idSection }) => (
            <Section
              key={idSection}
              idSection={idSection}
              title={title}
              description={description}
              image={image}
              items={items}
              inverted={inverted}
            />
          ))}
          <Featured />
          <RecentArticles />
          {stick && <ButtonUpScroll />}
        </Content>
        <Footer />
      </Container>
    </Layout>
  )
}

export default Home
