import React from 'react'
import { string, array, bool, object } from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'

import { white, grey, black } from '@/style-guide/colors'

import { LottieImage } from '@/components'
import SubCategoryCard from '@/components/SubCategoryCard'

const Container = styled.div`
  background-color: ${({ inverted }) => (inverted ? '#FAFAFA' : white)};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column-reverse;
  padding: 20px 25px;

  ${media.greaterThan('medium')`
    padding: 110px 160px;
    flex-direction: ${({ inverted }) => (inverted ? 'row-reverse' : 'row')};
  `}

  /* for wide monitor */
  ${media.greaterThan('2200px')`
    padding: 110px 440px;
  `}
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.span`
  font-size: 30.3969px;
  line-height: 46px;
  font-weight: bold;
  color: ${black};
  margin-top: 20px;

  ${media.greaterThan('medium')`
    margin-top: 0;
    font-size: 36.6px;
    line-height: 46px;
    flex-direction: ${({ inverted }) => (inverted ? 'row-reverse' : 'row')};
  `}
`

const Description = styled.span`
  font-size: 14px;
  line-height: 24px;
  color: ${grey};
  width: 100%;
  margin-top: 10px;

  ${media.greaterThan('medium')`
    max-width: 473px;
  `}
`

const Items = styled.div`
  margin-top: 10px;
`

const SectionImage = styled(LottieImage)`
  height: 178px;
  width: 281px;
  margin-top: 20px;

  ${media.greaterThan('medium')`
    margin-top: 128px;
    height: 397px;
    width: 625px;
  `}
`

const Section = ({ title, description, image, inverted, idSection, items = [] }) => (
  <Container inverted={inverted} id={idSection}>
    <Content>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Items>
        {items.map((item, index) => (
          <SubCategoryCard
            key={index}
            icon={item.icon}
            label={item.label}
            id={item.id}
            category={item.parent}
            disabled={item.disabled}
            link
          />
        ))}
      </Items>
    </Content>
    <SectionImage source={image} />
  </Container>
)

Section.propTypes = {
  title: string.isRequired,
  description: string.isRequired,
  image: object.isRequired,
  inverted: bool,
  items: array,
  idSection: string
}

export default Section
