import React from 'react'
import { Link as LinkComponent } from 'gatsby'
import { object } from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'

import { black, white, orange } from '@/style-guide/colors'

import topLogo from '@/assets/icons/top-logo.svg'
import atSign from '@/assets/icons/at-sign.svg'

import EndBar from './EndBar'

const Container = styled.div`
  background-color: #f7f7f7;
  width: 100%;
  margin-top: 50px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 25px;
  padding-top: 80px;

  ${media.greaterThan('medium')`
    padding: 110px 160px;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  `}
`

const Logo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > img {
    height: 16px;

    ${media.greaterThan('medium')`
      height: 23px;
    `}
  }
`

const ReportError = styled(LinkComponent)`
  width: 228px;
  height: 60px;
  background-color: ${white};
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 30px;
  text-decoration: none;

  :hover {
    opacity: 0.8;
  }

  > span {
    color: ${orange};
    font-weight: bold;
    font-size: 14px;
    line-height: 46px;
    margin-left: 15px;
  }
`

const ReportErrorDesktop = styled(ReportError)`
  display: none;
  ${media.greaterThan('medium')`
    display: flex;
  `}
`

const ReportErrorMobile = styled(ReportError)`
  display: flex;
  ${media.greaterThan('medium')`
    display: none;
  `}
`

const Options = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 50px;
  margin-top: 30px;

  ${media.greaterThan('medium')`
    margin-top: 0px
    grid-template-columns: 200px 200px;
  `}
`

const Link = styled.span`
  font-size: 14px;
  line-height: 46px;
  color: ${black};
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`

const Footer = ({ style }) => (
  <Container style={style}>
    <Content>
      <Logo>
        <img src={topLogo} alt="CalculateX" />
        <ReportErrorDesktop to="/contact">
          <img src={atSign} alt="report" />
          <span>Report an Error</span>
        </ReportErrorDesktop>
      </Logo>
      <Options>
        <Link>About Us</Link>
        <Link>Contact</Link>
        <Link>All Calculators</Link>
        <Link>Partinership</Link>
        <Link>Resources</Link>
        <Link>We&apos;re hiring!</Link>
        <Link>Blog</Link>
        <Link>Press kit</Link>
        <Link>Calculator collections</Link>
      </Options>
      <ReportErrorMobile to="/contact">
        <img src={atSign} alt="report" />
        <span>Report an Error</span>
      </ReportErrorMobile>
    </Content>
    <EndBar />
  </Container>
)

Footer.propTypes = {
  style: object
}

export default Footer
